import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<void, 'disabled'>({
	name: 'MainFooter',
	uniqId: 'T7bGB7',
})((theme, _, classes) => {
	return {
		logoImg: {
			height: 60,
			width: 'auto',
			maxWidth: '100%',
			objectFit: 'contain',
			objectPosition: 'center',

			[theme.breakpoints.down('lg')]: {
				height: '40px !important',
			},
		},
		content: {
			padding: theme.spacing(3.75 * 2, 0),
			[theme.breakpoints.down('md')]: {
				padding: theme.spacing(3.75, 0),
			},
		},
		navItemTitle: {
			textTransform: 'uppercase',
			marginBottom: theme.spacing(5),
			[theme.breakpoints.down('md')]: {
				marginBottom: theme.spacing(3.75),
			},
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(2.5),
			},
		},
		navItemContent: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2.5),
		},
		allRightsReserved: {
			padding: theme.spacing(0.5, 0),
			borderTop: `1px solid ${theme.palette.neutral[200]}`,
			// backgroundColor: theme.palette.red[100],
		},
		instagramButton: {
			'background': theme.palette.socialMedia.instagram,
			'&:hover': {
				background: theme.palette.socialMedia.darkInstagram,
			},
			[`&.${classes.disabled}`]: {
				background: theme.palette.action.disabled,
			},
		},
		contactGridItem: {
			order: 1,
		},
		contact: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(5),
			[theme.breakpoints.down('md')]: {
				gap: theme.spacing(3.75),
			},
			[theme.breakpoints.down('sm')]: {
				gap: theme.spacing(2.5),
			},
		},
		contactInfo: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2.5),
		},
		contactInfoItem: {
			flex: 1,
			display: 'flex',
			// justifyContent: 'space-between',
			gap: theme.spacing(0.8),
		},
		contactSocialMedia: {
			order: 2,
			display: 'flex',
			flexWrap: 'wrap',
			gap: theme.spacing(1),
			[theme.breakpoints.down('md')]: {
				order: 0,
			},
		},
		disabled: {},
	}
})

export default useStyles
