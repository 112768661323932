import { NoSsr } from '@mui/material'
import MainHeader from '@/layouts/MainLayout/components/MainHeader'
import MainFooter from '@/layouts/MainLayout/components/MainFooter'

import useStyles from './MainLayout.styles'
import MainContactForm from './components/MainContactForm'
import { useEffect, useMemo, useState } from 'react'
import { Category } from '@/utils/apis/services'
import servicesApi from '@/utils/apis/services/services.api'
import { axiosHelpers } from '@/utils/helpers'
import MainLayoutContext from './MainLayout.context'
import { useIntersectionObserver } from '@/hooks'
import AppBackToTop from '@/components/AppBackToTop'

type MainLayoutProps = {
	children: React.ReactNode
}

const MainLayout = (props: MainLayoutProps) => {
	const { children } = props
	const { isIntersecting, ref } = useIntersectionObserver({
		threshold: 0.3,
	})

	const [categoryList, setCategoryList] = useState<Category[] | null>(null)

	const { classes } = useStyles()

	const isVisible = useMemo(() => {
		return !isIntersecting
	}, [isIntersecting])

	useEffect(() => {
		const getCategoryList = async () => {
			try {
				const { data } = await servicesApi.fetchCategoryList()

				if (axiosHelpers.checkRequestSuccess(data)) setCategoryList(data.params.data)
			} catch (e) {
				console.log(e)
			}
		}

		getCategoryList()
	}, [])

	return (
		<MainLayoutContext.Provider value={{ categoryList }}>
			<div className={classes.root}>
				<NoSsr>{categoryList && <MainHeader />}</NoSsr>

				<main className={classes.main}>{children}</main>

				<NoSsr>
					<MainContactForm />
					<footer ref={ref}>{categoryList && <MainFooter />}</footer>
					<div className={`${classes.backToTopBtn} ${isVisible && 'active'}`}>
						<AppBackToTop />
					</div>
					{/* {isVisible && } */}
				</NoSsr>
				{/* <NoSsr>
        {!disabledMainFooter && <MainFooter />}
        <MainBottomAppBar
          disabledAppBar={disabledMainBottomAppBar}
          disabledAppBarToolbar={disabledMainBottomAppBarToolbar}
        />
        {!disabledFloatActions && (
          <FloatActions disabledSellIconButton={disabledSellIconButton} />
        )}
      </NoSsr> */}
			</div>
		</MainLayoutContext.Provider>
	)
}

export default MainLayout
