import { appYup } from '@/libs'

export type FormBookingValues = {
	name: string
	phone: string
	email: string
	services: string[]
	code: string
}

export const BookingSchema = appYup.object().shape({
	name: appYup.string().required('姓名為必填項'),
	phone: appYup.string().required('電話號碼為必填項'),
	email: appYup.string().required('電子郵件為必填項').email('電子郵件格式不正確'),
	services: appYup.array().min(1, '請選擇至少 1 項服務'),
	code: appYup.string().required('驗證碼為必填項'),
})

export const initFormBookingValues: FormBookingValues = {
	name: '',
	phone: '',
	email: '',
	services: [],
	code: '',
}
