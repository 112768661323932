import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles({
	name: 'AppBackToTop',
})((theme) => {
	return {
		root: {
			zIndex: 10,
			position: 'fixed',
			right: 28,
			bottom: 20,
		},

		typo: {
			...theme.typography.bodyMed16,
			fontSize: 22,
			marginLeft: theme.spacing(1),
		},
	}
})

export default useStyle
