import { useEffect } from "react";

const AppInitialize = () => {
  const updateSchemeIcon = (matcher: MediaQueryList) => () => {
    const lightSchemeIcon = document.querySelector("link#light-scheme-icon")!;
    const darkSchemeIcon = document.querySelector("link#dark-scheme-icon")!;
    if (matcher.matches) {
      document.head.append(darkSchemeIcon);
    } else {
      document.head.append(lightSchemeIcon);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const matcher = window.matchMedia("(prefers-color-scheme: dark)");
      matcher.addListener(updateSchemeIcon(matcher));
      updateSchemeIcon(matcher)();
      return () => {
        matcher.removeListener(updateSchemeIcon(matcher));
      };
    }
  }, []);

  return null;
};

export default AppInitialize;
