import AppImage from '@/components/AppImage'
import AppTypography from '@/components/AppTypography'
import AppTextField from '@/components/AppTextField'
import AppFormControlLabel from '@/components/AppFormControlLabel'
import AppCheckbox from '@/components/AppCheckbox'

import { useTranslation } from 'next-i18next'
import { FastField, FastFieldProps, Field, Form, Formik, FormikHelpers } from 'formik'
import { Box, FormControl, FormGroup, Stack } from '@mui/material'

import { BookingSchema, FormBookingValues, initFormBookingValues } from './MainContactForm.schema'
import { appCaptchaHelpers, axiosHelpers, commonHelpers, formikHelpers } from '@/utils/helpers'
import AppIconButton from '@/components/AppIconButton'
import AppSvgIcon from '@/components/AppSvgIcon'
import { Loop } from '@mui/icons-material'
import { Options as OptionsAppCaptcha } from '@/utils/helpers/appCaptcha'
import { useEffect, useState } from 'react'
import AppButton from '@/components/AppButton'
import { commonConstants } from '@/utils/constants'

import type { Service } from '@/utils/apis/contact/contact.api.types'
import { contactApi } from '@/utils/apis'
import AppFormHelperText from '@/components/AppFormHelperText'
import { toast } from 'react-toastify'

const FormBooking = () => {
	const [randomCode, setRandomCode] = useState<{ code: string; url: string }>({
		code: '',
		url: '',
	})

	const [services, setServices] = useState<Service[] | null>(null)

	const handleGenerateCode = () => {
		const options: OptionsAppCaptcha = {
			fontSize: 32,
		}

		setRandomCode(appCaptchaHelpers.generateCanvasUrl(4, options))
	}

	const handleSubmit = async (
		values: FormBookingValues,
		actions: FormikHelpers<FormBookingValues>,
	) => {
		const { code, ...params } = values
		if (values.code.toLowerCase() !== randomCode.code.toLowerCase())
			return toast.error('您輸入的驗證碼有誤，請重新輸入')
		try {
			const response = await contactApi.submitConsultationInfo({ params })
			if (axiosHelpers.checkRequestSuccess(response)) {
				actions.resetForm()
				handleGenerateCode()
				return toast.success('您的資訊已提交成功。')
			}
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		const getServicesList = async () => {
			try {
				const response = await contactApi.fetchServices()

				if (axiosHelpers.checkRequestSuccess(response))
					setServices(response.data.params.data)
			} catch (e) {
				console.log(e)
			}
		}

		getServicesList()
		handleGenerateCode()
	}, [])

	const { t } = useTranslation()

	return (
		<Formik
			initialValues={initFormBookingValues}
			validationSchema={BookingSchema}
			onSubmit={handleSubmit}>
			{(formikProps) => (
				<Form noValidate>
					<Stack spacing={{ xs: 2, lg: 3 }}>
						<FastField name='name'>
							{({ field, form, meta }: FastFieldProps) => (
								<AppTextField
									fullWidth
									required
									borderColor='neutral.400'
									label={t('form.name')}
									placeholder={t('form.placeholder.name')}
									error={
										!!formikHelpers.showFieldError({
											form,
											meta,
										})
									}
									helperText={formikHelpers.showFieldError({
										form,
										meta,
									})}
									{...field}
								/>
							)}
						</FastField>

						<FastField name='phone'>
							{({ field, form, meta }: FastFieldProps) => (
								<AppTextField
									fullWidth
									required
									borderColor='neutral.400'
									label={t('form.phone')}
									placeholder={t('form.placeholder.phone')}
									error={
										!!formikHelpers.showFieldError({
											form,
											meta,
										})
									}
									helperText={formikHelpers.showFieldError({
										form,
										meta,
									})}
									{...field}
								/>
							)}
						</FastField>

						<FastField name='email'>
							{({ field, form, meta }: FastFieldProps) => (
								<AppTextField
									fullWidth
									required
									borderColor='neutral.400'
									label={t('form.email')}
									placeholder={t('form.placeholder.email')}
									error={
										!!formikHelpers.showFieldError({
											form,
											meta,
										})
									}
									helperText={formikHelpers.showFieldError({
										form,
										meta,
									})}
									{...field}
								/>
							)}
						</FastField>

						<FormControl component='fieldset'>
							<AppTypography
								variant='bodyMed16'
								component='legend'>
								{t('form.servicesRequest')}
								<AppTypography
									component='span'
									color='error.main'>
									*
								</AppTypography>
							</AppTypography>
							<FormGroup row>
								{!commonHelpers.isEmpty(services) &&
									services!.map((service) => (
										<AppFormControlLabel
											name='services'
											key={service.id}
											label={service.name}
											control={
												<Field
													checked={formikProps.values.services.includes(
														service.id.toString(),
													)}
													as={AppCheckbox}
													color='neutral.200'
													value={service.id}
												/>
											}
										/>
									))}
							</FormGroup>

							<AppFormHelperText
								error
								sx={(theme) => ({ ...theme.typography.bodyReg14, fontSize: 12 })}>
								{formikProps.errors.services}
							</AppFormHelperText>
						</FormControl>

						<FastField name='code'>
							{({ field, form, meta }: FastFieldProps) => (
								<AppTextField
									fullWidth
									borderColor='neutral.400'
									placeholder={t('form.placeholder.code')}
									error={
										!!formikHelpers.showFieldError({
											form,
											meta,
										})
									}
									helperText={formikHelpers.showFieldError({
										form,
										meta,
									})}
									{...field}
								/>
							)}
						</FastField>

						<Stack
							direction='row'
							spacing={1}>
							<Box bgcolor='neutral.50'>
								<AppImage
									src={randomCode.url}
									objectFit='contain'
									objectPosition='center'
									width={187}
									height={56}
								/>
							</Box>
							<AppIconButton
								color='neutral.700'
								onClick={handleGenerateCode}>
								<AppSvgIcon component={Loop} />
							</AppIconButton>
						</Stack>

						<AppButton
							type='submit'
							variant='contained'>
							{t('button.submitInfo')}
						</AppButton>
					</Stack>
				</Form>
			)}
		</Formik>
	)
}

const MainContactForm = () => {
	const { t } = useTranslation()

	return (
		<Box
			component='section'
			py={{ xs: 5, lg: 70 / 8 }}>
			<Stack
				direction={{ xs: 'column-reverse', lg: 'row' }}
				alignItems='center'
				ml={{ xs: 0, lg: 350 / 8 }}
				mr={{ xs: 0, lg: 70 / 8 }}
				spacing={{ xs: 20 / 8, lg: 100 / 8 }}>
				<Stack
					id={commonConstants.contactFormId}
					flexBasis={{ xs: '100%', lg: 560 }}
					spacing={{ xs: 20 / 8, lg: 70 / 8 }}
					sx={{ px: { xs: 20 / 8, lg: 0 } }}>
					<AppTypography variant='title'>{t('homePage.bookingForm')}</AppTypography>

					<FormBooking />
				</Stack>

				<Box
					position='relative'
					flex={1}
					width='100%'
					height={833}>
					<AppImage
						src='/images/pages/home/home2.png'
						className='image'
						fill
					/>
				</Box>
			</Stack>
		</Box>
	)
}

export default MainContactForm
