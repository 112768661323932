import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
	name: 'MainLayout',
})((theme) => {
	return {
		root: {
			display: 'flex',
			flexDirection: 'column',
			backgroundColor: theme.palette.background.default,
			overflowX: 'clip',
		},
		main: {
			minHeight: 'calc(100dvh - 100px)',
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
		},

		backToTopBtn: {
			'visibility': 'hidden',
			'opacity': 0,
			'transition': 'all 0.3s',

			'&.active': {
				visibility: 'visible',
				opacity: 1,
			},
		},
	}
})

export default useStyles
