import { generateUtilityClasses } from "@mui/material";
import { alpha, darken, rgbToHex } from "@mui/material/styles";
import _get from "lodash/get";
import { makeStyles } from "tss-react/mui";

type MakeStylesParams = {
  color?: string;
  textColor?: string;
};

export const appIconButtonClasses = generateUtilityClasses("AppIconButton", [
  "root",
  "borderRadiusRounded",
  "borderRadiusCircular",
  "borderRadiusRoundedSizeSmall",
  "text",
  "textSizeMedium",
  "textSizeSmall",
  "textEdgeStart",
  "textEdgeEnd",
  "textEdgeTop",
  "textEdgeBottom",
  "textEdgeX",
  "textEdgeY",
  "textEdgeXY",
  "outlined",
  "outlinedSizeMedium",
  "outlinedSizeSmall",
  "outlinedEdgeStart",
  "outlinedEdgeEnd",
  "outlinedEdgeTop",
  "outlinedEdgeBottom",
  "outlinedEdgeX",
  "outlinedEdgeY",
  "outlinedEdgeXY",
  "containedTonal",
  "containedTonalSizeMedium",
  "containedTonalSizeSmall",
  "containedTonalEdgeStart",
  "containedTonalEdgeEnd",
  "containedTonalEdgeTop",
  "containedTonalEdgeBottom",
  "containedTonalEdgeX",
  "containedTonalEdgeY",
  "containedTonalEdgeXY",
  "contained",
  "containedSizeMedium",
  "containedSizeSmall",
  "containedEdgeStart",
  "containedEdgeEnd",
  "containedEdgeTop",
  "containedEdgeBottom",
  "containedEdgeX",
  "containedEdgeY",
  "containedEdgeXY",
  "disabled",
  "focusVisible",
]);

const useStyles = makeStyles<
  MakeStylesParams,
  | "containedTonalEdgeStart"
  | "containedTonalEdgeEnd"
  | "containedTonalEdgeTop"
  | "containedTonalEdgeBottom"
  | "containedTonalEdgeX"
  | "containedTonalEdgeY"
  | "containedTonalEdgeXY"
  | "containedEdgeStart"
  | "containedEdgeEnd"
  | "containedEdgeTop"
  | "containedEdgeBottom"
  | "containedEdgeX"
  | "containedEdgeY"
  | "containedEdgeXY"
  | "outlinedEdgeStart"
  | "outlinedEdgeEnd"
  | "outlinedEdgeTop"
  | "outlinedEdgeBottom"
  | "outlinedEdgeX"
  | "outlinedEdgeY"
  | "outlinedEdgeXY"
  | "textEdgeStart"
  | "textEdgeEnd"
  | "textEdgeTop"
  | "textEdgeBottom"
  | "textEdgeX"
  | "textEdgeY"
  | "textEdgeXY"
  | "disabled"
>({
  name: "AppIconButton",
  uniqId: "kta7l1",
})((theme, params, classes) => {
  let color = "";
  let textColor = "";
  const isDefaultTextColor =
    !params.textColor || params.textColor === "default";

  switch (params.color) {
    case "primary": {
      color = theme.palette.primary.main;
      textColor = theme.palette.common.white;
      if (!isDefaultTextColor) {
        textColor =
          _get(theme.palette, params.textColor ?? "") ??
          (params?.textColor || theme.palette.text.primary);
      }
      break;
    }
    case "secondary": {
      color = theme.palette.secondary.main;
      textColor = theme.palette.common.white;
      if (!isDefaultTextColor) {
        textColor =
          _get(theme.palette, params.textColor ?? "") ??
          (params?.textColor || theme.palette.text.primary);
      }
      break;
    }
    case "error": {
      color = theme.palette.error.main;
      textColor = theme.palette.common.white;
      if (!isDefaultTextColor) {
        textColor =
          _get(theme.palette, params.textColor ?? "") ??
          (params?.textColor || theme.palette.text.primary);
      }
      break;
    }
    default: {
      color =
        _get(theme.palette, params?.color ?? "") ??
        (params?.color || theme.palette.primary.main);
      if (isDefaultTextColor) {
        textColor = theme.palette.getContrastText(color);
      } else {
        textColor =
          _get(theme.palette, params.textColor ?? "") ??
          (params?.textColor || theme.palette.primary.main);
      }
      break;
    }
  }

  // Medium
  const containedTonalSizeMediumPaddingX = "10px" as unknown as number;
  const containedTonalSizeMediumPaddingY = "10px" as unknown as number;

  const containedSizeMediumPaddingX = "10px" as unknown as number;
  const containedSizeMediumPaddingY = "10px" as unknown as number;

  const textSizeMediumPaddingX = "10px" as unknown as number;
  const textSizeMediumPaddingY = "10px" as unknown as number;

  const outlinedSizeMediumPaddingX = "9px" as unknown as number;
  const outlinedSizeMediumPaddingY = "9px" as unknown as number;
  // Small
  const containedTonalSizeSmallPaddingX = "8px" as unknown as number;
  const containedTonalSizeSmallPaddingY = "8px" as unknown as number;

  const containedSizeSmallPaddingX = "8px" as unknown as number;
  const containedSizeSmallPaddingY = "8px" as unknown as number;

  const textSizeSmallPaddingX = "8px" as unknown as number;
  const textSizeSmallPaddingY = "8px" as unknown as number;

  const outlinedSizeSmallPaddingX = "7px" as unknown as number;
  const outlinedSizeSmallPaddingY = "7px" as unknown as number;

  return {
    root: {
      color,
      // borderRadius: theme.shape.borderRadius,
      "&:hover": {
        backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
      },
    },
    borderRadiusRounded: {
      borderRadius: theme.shape.borderRadius,
    },
    borderRadiusCircular: {
      borderRadius: "50px",
    },
    borderRadiusRoundedSizeSmall: {
      borderRadius: theme.shape.borderRadius / 2,
    },

    text: {
      [`&.${classes.disabled}`]: {
        color: theme.palette.text.disabled,
      },
    },
    textSizeMedium: {
      fontSize: 24,
      padding: theme.spacing(textSizeMediumPaddingY, textSizeMediumPaddingX),
    },
    textSizeSmall: {
      fontSize: 20,
      padding: theme.spacing(textSizeSmallPaddingY, textSizeSmallPaddingX),
      [`&.${classes.textEdgeStart}`]: {
        marginLeft: `-${theme.spacing(textSizeSmallPaddingX)}`,
      },
      [`&.${classes.textEdgeEnd}`]: {
        marginRight: `-${theme.spacing(textSizeSmallPaddingX)}`,
      },
      [`&.${classes.textEdgeTop}`]: {
        marginTop: `-${theme.spacing(textSizeSmallPaddingY)}`,
      },
      [`&.${classes.textEdgeBottom}`]: {
        marginBottom: `-${theme.spacing(textSizeSmallPaddingY)}`,
      },
      [`&.${classes.textEdgeX}`]: {
        marginLeft: `-${theme.spacing(textSizeSmallPaddingX)}`,
        marginRight: `-${theme.spacing(textSizeSmallPaddingX)}`,
      },
      [`&.${classes.textEdgeY}`]: {
        marginTop: `-${theme.spacing(textSizeSmallPaddingY)}`,
        marginBottom: `-${theme.spacing(textSizeSmallPaddingY)}`,
      },
      [`&.${classes.textEdgeXY}`]: {
        marginTop: `-${theme.spacing(textSizeSmallPaddingY)}`,
        marginBottom: `-${theme.spacing(textSizeSmallPaddingY)}`,
        marginLeft: `-${theme.spacing(textSizeSmallPaddingX)}`,
        marginRight: `-${theme.spacing(textSizeSmallPaddingX)}`,
      },
    },
    textEdgeStart: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    textEdgeEnd: {
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    textEdgeTop: {
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    textEdgeBottom: {
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    textEdgeX: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    textEdgeY: {
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    textEdgeXY: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },

    outlined: {
      border: `1px solid ${color}`,
      color: isDefaultTextColor ? color : textColor,
      "&:hover": {
        backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
      },
      [`&.${classes.disabled}`]: {
        color: theme.palette.text.disabled,
        borderColor: theme.palette.text.disabled,
      },
    },
    outlinedSizeMedium: {
      fontSize: 24,
      padding: theme.spacing(
        outlinedSizeMediumPaddingY,
        outlinedSizeMediumPaddingX
      ),
    },
    outlinedSizeSmall: {
      fontSize: 20,
      padding: theme.spacing(
        outlinedSizeSmallPaddingY,
        outlinedSizeSmallPaddingX
      ),
      [`&.${classes.outlinedEdgeStart}`]: {
        marginLeft: `-${theme.spacing(outlinedSizeSmallPaddingX)}`,
      },
      [`&.${classes.outlinedEdgeEnd}`]: {
        marginRight: `-${theme.spacing(outlinedSizeSmallPaddingX)}`,
      },
      [`&.${classes.outlinedEdgeTop}`]: {
        marginTop: `-${theme.spacing(outlinedSizeSmallPaddingY)}`,
      },
      [`&.${classes.outlinedEdgeBottom}`]: {
        marginBottom: `-${theme.spacing(outlinedSizeSmallPaddingY)}`,
      },
      [`&.${classes.outlinedEdgeX}`]: {
        marginLeft: `-${theme.spacing(outlinedSizeSmallPaddingX)}`,
        marginRight: `-${theme.spacing(outlinedSizeSmallPaddingX)}`,
      },
      [`&.${classes.outlinedEdgeY}`]: {
        marginTop: `-${theme.spacing(outlinedSizeSmallPaddingY)}`,
        marginBottom: `-${theme.spacing(outlinedSizeSmallPaddingY)}`,
      },
      [`&.${classes.outlinedEdgeXY}`]: {
        marginTop: `-${theme.spacing(outlinedSizeSmallPaddingY)}`,
        marginBottom: `-${theme.spacing(outlinedSizeSmallPaddingY)}`,
        marginLeft: `-${theme.spacing(outlinedSizeSmallPaddingX)}`,
        marginRight: `-${theme.spacing(outlinedSizeSmallPaddingX)}`,
      },
    },
    outlinedEdgeStart: {
      marginLeft: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
    },
    outlinedEdgeEnd: {
      marginRight: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
    },
    outlinedEdgeTop: {
      marginTop: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
    },
    outlinedEdgeBottom: {
      marginBottom: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
    },
    outlinedEdgeX: {
      marginLeft: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
    },
    outlinedEdgeY: {
      marginTop: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
    },
    outlinedEdgeXY: {
      marginLeft: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(outlinedSizeMediumPaddingX)}`,
      marginTop: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(outlinedSizeMediumPaddingY)}`,
    },

    containedTonal: {
      backgroundColor: alpha(color, theme.palette.action.tonalOpacity),
      color: isDefaultTextColor ? color : textColor,
      "&:hover": {
        backgroundColor: darken(
          rgbToHex(alpha(color, theme.palette.action.tonalOpacity)),
          theme.palette.action.hoverOpacity
        ),
      },
      [`&.${classes.disabled}`]: {
        backgroundColor: theme.palette.text.disabled,
        color: theme.palette.common.white,
      },
    },
    containedTonalSizeMedium: {
      fontSize: 24,
      padding: theme.spacing(
        containedTonalSizeMediumPaddingY,
        containedTonalSizeMediumPaddingX
      ),
    },
    containedTonalSizeSmall: {
      fontSize: 20,
      padding: theme.spacing(
        containedTonalSizeSmallPaddingY,
        containedTonalSizeSmallPaddingX
      ),
      [`&.${classes.containedTonalEdgeStart}`]: {
        marginLeft: `-${theme.spacing(containedTonalSizeSmallPaddingX)}`,
      },
      [`&.${classes.containedTonalEdgeEnd}`]: {
        marginRight: `-${theme.spacing(containedTonalSizeSmallPaddingX)}`,
      },
      [`&.${classes.containedTonalEdgeTop}`]: {
        marginTop: `-${theme.spacing(containedTonalSizeSmallPaddingY)}`,
      },
      [`&.${classes.containedTonalEdgeBottom}`]: {
        marginBottom: `-${theme.spacing(containedTonalSizeSmallPaddingY)}`,
      },
      [`&.${classes.containedTonalEdgeX}`]: {
        marginLeft: `-${theme.spacing(containedTonalSizeSmallPaddingX)}`,
        marginRight: `-${theme.spacing(containedTonalSizeSmallPaddingX)}`,
      },
      [`&.${classes.containedTonalEdgeY}`]: {
        marginTop: `-${theme.spacing(containedTonalSizeSmallPaddingY)}`,
        marginBottom: `-${theme.spacing(containedTonalSizeSmallPaddingY)}`,
      },
      [`&.${classes.containedTonalEdgeXY}`]: {
        marginTop: `-${theme.spacing(containedTonalSizeSmallPaddingY)}`,
        marginBottom: `-${theme.spacing(containedTonalSizeSmallPaddingY)}`,
        marginLeft: `-${theme.spacing(containedTonalSizeSmallPaddingX)}`,
        marginRight: `-${theme.spacing(containedTonalSizeSmallPaddingX)}`,
      },
    },
    containedTonalEdgeStart: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    containedTonalEdgeEnd: {
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    containedTonalEdgeTop: {
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    containedTonalEdgeBottom: {
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    containedTonalEdgeX: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    containedTonalEdgeY: {
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    containedTonalEdgeXY: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },

    contained: {
      backgroundColor: color,
      color: textColor,
      "&:hover": {
        backgroundColor: darken(color, theme.palette.action.hoverOpacity),
      },
      [`&.${classes.disabled}`]: {
        backgroundColor: theme.palette.text.disabled,
        color: theme.palette.common.white,
      },
    },
    containedSizeMedium: {
      fontSize: 24,
      padding: theme.spacing(
        containedSizeMediumPaddingY,
        containedSizeMediumPaddingX
      ),
    },
    containedSizeSmall: {
      fontSize: 20,
      padding: theme.spacing(
        containedSizeSmallPaddingY,
        containedSizeSmallPaddingX
      ),
      [`&.${classes.containedEdgeStart}`]: {
        marginLeft: `-${theme.spacing(containedSizeSmallPaddingX)}`,
      },
      [`&.${classes.containedEdgeEnd}`]: {
        marginRight: `-${theme.spacing(containedSizeSmallPaddingX)}`,
      },
      [`&.${classes.containedEdgeTop}`]: {
        marginTop: `-${theme.spacing(containedSizeSmallPaddingY)}`,
      },
      [`&.${classes.containedEdgeBottom}`]: {
        marginBottom: `-${theme.spacing(containedSizeSmallPaddingY)}`,
      },
      [`&.${classes.containedEdgeX}`]: {
        marginLeft: `-${theme.spacing(containedSizeSmallPaddingX)}`,
        marginRight: `-${theme.spacing(containedSizeSmallPaddingX)}`,
      },
      [`&.${classes.containedEdgeY}`]: {
        marginTop: `-${theme.spacing(containedSizeSmallPaddingY)}`,
        marginBottom: `-${theme.spacing(containedSizeSmallPaddingY)}`,
      },
      [`&.${classes.containedEdgeXY}`]: {
        marginTop: `-${theme.spacing(containedSizeSmallPaddingY)}`,
        marginBottom: `-${theme.spacing(containedSizeSmallPaddingY)}`,
        marginLeft: `-${theme.spacing(containedSizeSmallPaddingX)}`,
        marginRight: `-${theme.spacing(containedSizeSmallPaddingX)}`,
      },
    },
    containedEdgeStart: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    containedEdgeEnd: {
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    containedEdgeTop: {
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    containedEdgeBottom: {
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    containedEdgeX: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
    },
    containedEdgeY: {
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    containedEdgeXY: {
      marginLeft: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginRight: `-${theme.spacing(textSizeMediumPaddingX)}`,
      marginTop: `-${theme.spacing(textSizeMediumPaddingY)}`,
      marginBottom: `-${theme.spacing(textSizeMediumPaddingY)}`,
    },
    disabled: {},
    focusVisible: {},
  };
});

export default useStyles;
