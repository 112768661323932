import { VerticalAlignTop } from '@mui/icons-material'

import AppIconButton from '../AppIconButton'
import AppButton from '../AppButton'
import AppSvgIcon from '../AppSvgIcon'

import { Stack, useMediaQuery, Theme } from '@mui/material'

import WhatsappIcon from '@@/public/images/icons/whatsapp.svg'

import useStyle from './AppBackToTop.styles'
import { useEffect, useState } from 'react'
import { axiosHelpers, commonHelpers } from '@/utils/helpers'
import { socialApi } from '@/utils/apis'
import { Social } from '@/utils/apis/social'
import { isEmpty } from 'lodash'
import AppLink from '../AppLink'

const AppBackToTop = () => {
	const [whatsappSocial, setWhatsappSocial] = useState<Social | null>(null)

	const fetchSocialList = async () => {
		try {
			const { data } = await socialApi.fetchSocial()
			if (axiosHelpers.checkRequestSuccess(data)) {
				const temp = data.params.data.find(
					(social) => social.name.toLowerCase() === 'whatsapp',
				)

				if (!isEmpty(temp)) setWhatsappSocial(temp)
			}
		} catch (e) {
			console.log(e)
		}
	}

	const isDownSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	const handleScrollToTop = () => {
		if (!commonHelpers.isBrowser()) return
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	useEffect(() => {
		if (isEmpty(whatsappSocial)) fetchSocialList()
	}, [whatsappSocial])

	const { classes } = useStyle()

	return (
		<Stack
			position='relative'
			width='100%'
			height='100%'>
			<Stack
				className={classes.root}
				alignItems='center'
				spacing={20 / 8}>
				{!isEmpty(whatsappSocial) && (
					<AppButton
						component={AppLink}
						href={whatsappSocial.link}
						target='_blank'
						hoverColor='none'
						underline='none'
						size={isDownSm ? 'small' : 'medium'}
						borderRadius='rounded'
						color='#2AA81A'
						variant='contained'>
						<AppSvgIcon
							size='inherit'
							component={WhatsappIcon}
						/>
						{!isDownSm && <span className={classes.typo}>免費諮詢</span>}
					</AppButton>
				)}

				<AppIconButton
					onClick={handleScrollToTop}
					variant='contained'
					sx={{ borderRadius: 999, width: 'fit-content' }}
					color='primary'>
					<AppSvgIcon
						fontSize='inherit'
						component={VerticalAlignTop}
					/>
				</AppIconButton>
			</Stack>
		</Stack>
	)
}

export default AppBackToTop
