import { Grid, Stack, Theme, useMediaQuery } from '@mui/material'

import AppLink from '@/components/AppLink'
import AppImage from '@/components/AppImage'
import AppSvgIcon from '@/components/AppSvgIcon'
import AppIconButton from '@/components/AppIconButton'
import AppContainer from '@/components/AppContainer'
import AppTypography from '@/components/AppTypography'

import EmailIcon from '@@/public/images/icons/email.svg'
import PhoneIcon from '@@/public/images/icons/phone-ic.svg'
import LocationIcon from '@@/public/images/icons/location-ic.svg'

import { useTranslation } from 'next-i18next'

import useStyles from './MainFooter.styles'
import { useContext, useEffect, useMemo, useState } from 'react'
import { isEmpty } from '@/utils/helpers/common'
import { socialApi } from '@/utils/apis'
import { axiosHelpers } from '@/utils/helpers'
import { Social } from '@/utils/apis/social'
import { BASE_CDN } from '@/utils/config/common.config'
import MainLayoutContext from '../../MainLayout.context'

const NavigationList = () => {
	const { categoryList } = useContext(MainLayoutContext)

	const { t } = useTranslation()

	const navMenus = useMemo(() => {
		const transformCategoryList = categoryList!.map((category) => ({
			title: category.name,
			href: `/our-services/${category.id}`,
			pathnameReg: new RegExp(`^/our-services/${category.id}`),
		}))

		return [
			{
				title: t('home'),
				href: '/',
				pathnameReg: new RegExp('^/home'),
			},
			...transformCategoryList,
		]
	}, [t])

	return navMenus.map((navigationItem, navigationItemIndex) => (
		<div key={`index-${navigationItemIndex}`}>
			<AppLink
				href={navigationItem.href}
				color='white'>
				{navigationItem.title}
			</AppLink>
		</div>
	))
}

const SocialContact = () => {
	const [socialList, setSocialList] = useState<Social[]>([])

	const fetchSocialList = async () => {
		try {
			const { data } = await socialApi.fetchSocial()
			if (axiosHelpers.checkRequestSuccess(data)) setSocialList(data.params.data)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		if (isEmpty(socialList)) fetchSocialList()
	}, [socialList])

	const { classes } = useStyles()

	return (
		<div className={classes.contact}>
			<div className={classes.contactInfo}>
				<div className={classes.contactInfoItem}>
					<AppSvgIcon
						color='white'
						component={PhoneIcon}
					/>
					<AppLink
						disabledNextLink
						underline='none'
						href='tel:31130918'
						color='white'>
						3113 0918
					</AppLink>
				</div>
				<div className={classes.contactInfoItem}>
					<AppSvgIcon
						color='white'
						component={EmailIcon}
					/>
					<AppLink
						disabledNextLink
						underline='none'
						href='mailto:info@elandco.com.hk'
						color='white'>
						info@elandco.com.hk
					</AppLink>
				</div>
				<div className={classes.contactInfoItem}>
					<AppSvgIcon
						color='white'
						component={LocationIcon}
					/>
					<AppLink
						disabledNextLink
						underline='none'
						href='https://maps.google.com/maps?q=central%2088%20+(central%2088)&amp;t=&z=18&ie=UTF8&iwloc=&language=zh-HK'
						color='white'
						target='_blank'>
						香港中環德輔道中88號中環88 3樓
					</AppLink>
				</div>
			</div>
			<div className={classes.contactSocialMedia}>
				{!isEmpty(socialList) &&
					socialList.map((social) => (
						<AppIconButton
							key={social.id}
							variant='contained'
							borderRadius='circular'
							color='#3F100E'
							component={AppLink}
							disabledNextLink
							href={social.link}
							target='_blank'
							hoverColor='none'
							underline='none'>
							<AppImage
								src={BASE_CDN + social.icon}
								width={40}
								height={40}
							/>
						</AppIconButton>
					))}
			</div>
		</div>
	)
}

const MainFooter = () => {
	const { classes } = useStyles()

	const isDownMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

	const { t } = useTranslation()

	return (
		<Stack bgcolor='red.800'>
			<div className={classes.content}>
				<AppContainer>
					<Grid
						container
						justifyContent={{ xs: 'start', md: 'space-between' }}
						spacing={6}>
						<Grid
							item
							width='100%'
							maxWidth={{ xs: 126, md: 156 }}>
							<AppLink
								href='#'
								underline='none'
								hoverColor='none'
								display='block'>
								<AppImage
									fill
									className={`${classes.logoImg} image`}
									src='/images/logo-white.png'
									unoptimized
								/>
							</AppLink>
						</Grid>

						{!isDownMd && (
							<>
								<Grid
									item
									md='auto'>
									<AppTypography
										color='white'
										className={classes.navItemTitle}>
										{t('navigation')}
									</AppTypography>
									<div className={classes.navItemContent}>
										<NavigationList />
									</div>
								</Grid>
								<Grid
									item
									md='auto'>
									<AppTypography
										className={classes.navItemTitle}
										color='white'>
										{t('contact')}
									</AppTypography>

									<SocialContact />
								</Grid>
							</>
						)}

						{isDownMd && (
							<Grid
								item
								xs={8}>
								<Stack
									direction='row'
									justifyContent='space-between'
									flexWrap='wrap'
									useFlexGap
									spacing={4}>
									<div>
										<AppTypography
											color='white'
											className={classes.navItemTitle}>
											{t('navigation')}
										</AppTypography>
										<div className={classes.navItemContent}>
											<NavigationList />
										</div>
									</div>

									<div>
										<AppTypography
											className={classes.navItemTitle}
											color='white'>
											{t('contact')}
										</AppTypography>

										<SocialContact />
									</div>
								</Stack>
							</Grid>
						)}
					</Grid>
				</AppContainer>
			</div>
			<div className={classes.allRightsReserved}>
				<AppContainer>
					<AppTypography
						variant='bodyReg14'
						color='white'
						align='center'>
						{t('allRightsReservedContent_text')}
						{new Date().getFullYear()}
					</AppTypography>
				</AppContainer>
			</div>
		</Stack>
	)
}

export default MainFooter
