import AppLink from '@/components/AppLink'
import AppButton from '@/components/AppButton'
import AppSvgIcon from '@/components/AppSvgIcon'
import AppMenu from '@/components/AppMenu'
import AppMenuItem from '@/components/AppMenuItem'

import ArrowDropdownIcon from '@@/public/images/icons/arrow-drop-down.svg'

import { useContext, useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import MainHeaderContext from '@/layouts/MainLayout/components/MainHeader/MainHeader.context'

import useStyles from './NavMenu.styles'

import type { MainHeaderContextValue } from '@/layouts/MainLayout/components/MainHeader/MainHeader.context'

type NavMenuButtonProps = {
	navMenu: MainHeaderContextValue['navMenus'][number]
	selected?: boolean
}

const NavMenuButton = (props: NavMenuButtonProps) => {
	const { navMenu, selected } = props

	const router = useRouter()

	const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null)
	const subMenuOpen = !!subMenuAnchorEl

	const hasSubMenu = !!navMenu.items?.length

	const { classes, cx } = useStyles()

	const selectedSubNavMenuIndex = useMemo(() => {
		if (!hasSubMenu) return

		return navMenu!.items!.findIndex((sub) => sub.pathnameReg.test(router.asPath))
	}, [router.asPath, navMenu])

	const handleSubMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setSubMenuAnchorEl(event.currentTarget)
	}

	const handleSubMenuClose = () => {
		setSubMenuAnchorEl(null)
	}

	return (
		<>
			<AppButton
				color={!!selected ? 'primary' : 'text.primary'}
				sx={{ fontWeight: selected ? 500 : 400 }}
				classes={{
					root: cx(classes.navMenuButton, subMenuOpen && classes.open),
					endIcon: classes.navMenuButtonEndIcon,
				}}
				endIcon={hasSubMenu && <AppSvgIcon component={ArrowDropdownIcon} />}
				{...(!hasSubMenu
					? {
							component: AppLink,
							href: navMenu.href,
							underline: 'none',
					  }
					: {
							onClick: handleSubMenuOpen,
					  })}>
				{navMenu.title}
			</AppButton>
			{hasSubMenu && (
				<AppMenu
					anchorEl={subMenuAnchorEl}
					open={subMenuOpen}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					PaperProps={{
						sx: {
							mt: 1,
						},
					}}
					onClose={handleSubMenuClose}>
					{navMenu.items!.map((subMenu, subMenuIndex) => (
						<AppMenuItem
							key={subMenuIndex}
							component={AppLink}
							href={subMenu.href}
							underline='none'
							onClick={handleSubMenuClose}>
							{subMenu.title}
						</AppMenuItem>
					))}
				</AppMenu>
			)}
		</>
	)
}

const NavMenu = () => {
	const { navMenus } = useContext(MainHeaderContext)

	const { classes } = useStyles()

	const router = useRouter()

	const selectedNavMenuIndex = useMemo(() => {
		if (router.asPath === '/') return 0

		return navMenus.findIndex((navMenus) => navMenus.pathnameReg.test(router.asPath))
	}, [router.asPath, navMenus])

	return (
		<div className={classes.root}>
			{navMenus.map((navMenu, navMenuIndex) => (
				<NavMenuButton
					key={navMenuIndex}
					navMenu={navMenu}
					selected={selectedNavMenuIndex === navMenuIndex}
				/>
			))}
		</div>
	)
}

export default NavMenu
