import { commonAxios } from '@/libs'
import { AxiosResponseData } from '@/libs/axios'
import { Category, CategoryList, DetailService } from './services.api.types'

const servicesApi = {
	fetchCategoryList: () => {
		return commonAxios.get<AxiosResponseData<CategoryList>>('/Categories/getList')
	},

	fetchDetailCategory: (id: number | string) => {
		return commonAxios.get<AxiosResponseData<Category>>(
			`/Categories/getDetail/${id.toString()}`,
		)
	},

	fetchDetailService: (payload: { categoryId: number }) => {
		return commonAxios.get<AxiosResponseData<DetailService>>('/Services/getService', {
			params: { category_id: payload.categoryId },
		})
	},
}

export default servicesApi
