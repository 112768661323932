import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<void, 'open'>({
	name: 'NavMenu',
	uniqId: 'yUnVVR',
})((theme, _, classes) => {
	return {
		root: {
			flex: 1,
			display: 'flex',
			justifyContent: 'center',
			overflow: 'clip',
			gap: theme.spacing(2.5),
		},
		navMenuButton: {
			'fontWeight': 400,
			'&:hover': {
				color: theme.palette.primary.main,
			},
			[`&.${classes.open}`]: {
				color: theme.palette.primary.main,
			},
			[theme.breakpoints.down('md')]: {
				display: 'none',
			},
		},
		navMenuButtonEndIcon: {
			transform: 'rotate(0deg)',
			transition: theme.transitions.create(['transform']),
			[`.${classes.open} &`]: {
				transform: 'rotate(180deg)',
			},
		},
		open: {},
	}
})

export default useStyles
