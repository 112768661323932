import { AppBar } from '@mui/material'
import AppContainer from '@/components/AppContainer'
import AppLink from '@/components/AppLink'
import MainHeaderAppBarToolbar from '@/components/MainHeaderAppBarToolbar'
import AppImage from '@/components/AppImage'
import NavMenu from '@/layouts/MainLayout/components/MainHeader/components/NavMenu'
import Sidebar from '@/layouts/MainLayout/components/MainHeader/components/Sidebar'
import AppButton from '@/components/AppButton'

import { useContext, useMemo } from 'react'
import { useTranslation } from 'next-i18next'

import MainHeaderContext from '@/layouts/MainLayout/components/MainHeader/MainHeader.context'

import useStyles from './MainHeader.styles'
import { commonConstants } from '@/utils/constants'
import MainLayoutContext from '../../MainLayout.context'

const MainHeader = () => {
	const { categoryList } = useContext(MainLayoutContext)

	const { classes } = useStyles()

	const { t } = useTranslation()

	const navMenus = useMemo(() => {
		const transformCategoryList = categoryList!.map((category) => ({
			title: category.name,
			href: `/our-services/${category.id}`,
			pathnameReg: new RegExp(`^/our-services/${category.id}`),
		}))

		return [
			{
				title: t('home'),
				href: '/',
				pathnameReg: new RegExp('^/home'),
			},
			{
				title: t('ourServices'),
				href: '/our-services',
				pathnameReg: new RegExp('^(/our-services|/related-knowledge|/reference)'),
				items: [...transformCategoryList],
			},
			{
				title: t('aboutUs'),
				href: '/about',
				pathnameReg: new RegExp('^/about'),
			},
			{
				title: t('contactUs'),
				href: '/contact',
				pathnameReg: new RegExp('^/contact'),
			},
		]
	}, [t])

	return (
		<MainHeaderContext.Provider
			value={{
				navMenus,
			}}>
			<AppBar
				component='header'
				className={classes.root}
				elevation={0}
				color='default'>
				<AppContainer maxWidth='xl'>
					<MainHeaderAppBarToolbar disableGutters>
						<Sidebar />

						<AppLink
							href='/'
							underline='none'
							hoverColor='none'>
							<AppImage
								className={`${classes.logoImg} image`}
								fill
								src='/images/logo.png'
								unoptimized
							/>
						</AppLink>

						<NavMenu />

						<AppButton
							component={AppLink}
							href={'#' + commonConstants.contactFormId}
							variant='contained'
							underline='none'
							hoverColor='none'
							noWrap>
							{t('bookNow')}
						</AppButton>
					</MainHeaderAppBarToolbar>
				</AppContainer>
			</AppBar>
			<MainHeaderAppBarToolbar />
		</MainHeaderContext.Provider>
	)
}

export default MainHeader
