import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles({
	name: 'MainHeaderAppBarToolbar',
})((theme) => {
	return {
		root: {
			width: '100%',
			position: 'relative',
			height: 100,
			minHeight: 100,

			[theme.breakpoints.down('lg')]: {
				height: 80,
				minHeight: 80,
			},
		},
	}
})

export default useStyles
