import { Category } from '@/utils/apis/services'
import { createContext } from 'react'

type MainLayoutContextValue = {
	categoryList: Category[] | null
}

const MainLayoutContext = createContext<MainLayoutContextValue>(null!)

export default MainLayoutContext
